import { times } from '@lc/libutils'

import classes from '@/util/classes'

import styles from './Spinner.scss'

export default function Spinner({ mini }: { mini?: boolean }) {
	return (
		<div className={classes(styles.spinner, [styles.mini, mini])}>
			{times(5, i => (
				<div
					key={i}
					className={styles.inner}
					style={{ animationDelay: `${i * 100}ms` }}
				/>
			))}
		</div>
	)
}
