import type { Dispatch } from '@reduxjs/toolkit'

import type { Branch } from '@horfix/horfix-common/types/data/data'

import { Executor } from '@lc/librunner'

import { APIError, apiCall } from '@/util/apiCall'

import { setCurrent } from './gen'
import {
	type ProjectFlag,
	setProjectName,
	setProjectFlags,
	clearProject,
	setProject,
	filterVisibleBranches,
} from './project'

export async function updateGen(dispatch: Dispatch) {
	try {
		const { gen } = await apiCall('/data/:project/gen/status', {
			type: true,
			params: { project: 'default' },
		})
		try {
			const { user } = await apiCall('/users/keys/:user', {
				params: { user: gen.user },
				type: true,
			})
			dispatch(setCurrent({ ...gen, user_info: user }))
		} catch {
			const user = {
				display_name: 'N/A',
				email: 'N/A',
			}
			dispatch(setCurrent({ ...gen, user_info: user }))
		}
	} catch (e) {
		if (APIError.isCode(e, 404)) {
			dispatch(setCurrent(null))
		} else {
			console.error('updateGen', e)
		}
	}
}

export async function loadProject(dispatch: Dispatch) {
	const { project } = await apiCall('/projects/keys/:project', {
		type: true,
		params: { project: 'default' },
	})
	const { branches } = await apiCall('/data/:project/data', {
		type: true,
		params: { project: 'default' },
	})
	const flags = await loadProjectFlags(branches)
	dispatch(
		setProject({
			uuid: project.uuid,
			name: project.name,
			logo: project.logo,
			flags: [...flags],
			branches,
		}),
	)
}

async function loadProjectFlags(branches: Branch[]): Promise<Set<ProjectFlag>> {
	const exe = new Executor({ concurrencyLimit: 5 })
	const [
		enableFhp,
		enableFhl,
		useDepositaires,
		useFIP,
		usePoI,
		useQRFhp,
		useQRFip,
		kioskConfig,
	] = await exe.scheduleAndWaitAll([
		async () => {
			const { data: enableFhp } = await apiCall(
				'/data/:project/config/pub/enableFhp',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return enableFhp
		},
		async () => {
			const { data: enableFhl } = await apiCall(
				'/data/:project/config/pub/enableFhl',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return enableFhl
		},
		async () => {
			const { data: useDepositaires } = await apiCall(
				'/data/:project/config/pub/useDepositaires',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return useDepositaires
		},
		async () => {
			const { data: useFIP } = await apiCall(
				'/data/:project/config/pub/useFIP',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return useFIP
		},
		async () => {
			const { data: usePoI } = await apiCall(
				'/data/:project/config/pub/usePoI',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return usePoI
		},
		async () => {
			const { data: useQRFhp } = await apiCall(
				'/data/:project/config/pub/useQRFhp',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return useQRFhp
		},
		async () => {
			const { data: useQRFip } = await apiCall(
				'/data/:project/config/pub/useQRFip',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return useQRFip
		},
		async () => {
			const { data: kioskConfig } = await apiCall(
				'/data/:project/config/pub/kioskConfig',
				{
					type: true,
					params: { project: 'default' },
				},
			)
			return kioskConfig
		},
	] as const)

	const flags = new Set<ProjectFlag>()
	if (enableFhp) flags.add('fhp')
	if (enableFhl) flags.add('fhl')
	if (useDepositaires) flags.add('depositaires')
	if (useFIP === 'specific') flags.add('fip')
	if (usePoI) flags.add('poi')
	if (useQRFhp.use !== 'off' || useQRFip.use !== 'off') flags.add('qr')
	if (filterVisibleBranches(branches).length > 1) flags.add('branches')
	if (kioskConfig) flags.add('kiosk')
	return flags
}

export async function updateProject(dispatch: Dispatch) {
	try {
		const exe = new Executor({ concurrencyLimit: 5 })
		const [
			,
			branches,
			enableFhp,
			enableFhl,
			useDepositaires,
			useFIP,
			usePoI,
			useQRFhp,
			useQRFip,
			kioskConfig,
		] = await exe.scheduleAndWaitAll([
			async () => {
				const { project } = await apiCall('/projects/keys/:project', {
					type: true,
					params: { project: 'default' },
				})
				dispatch(setProjectName(project.name))
			},
			async () => {
				const { branches } = await apiCall('/data/:project/data', {
					type: true,
					params: { project: 'default' },
				})
				return branches
			},
			async () => {
				const { data: enableFhp } = await apiCall(
					'/data/:project/config/pub/enableFhp',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return enableFhp
			},
			async () => {
				const { data: enableFhl } = await apiCall(
					'/data/:project/config/pub/enableFhl',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return enableFhl
			},
			async () => {
				const { data: useDepositaires } = await apiCall(
					'/data/:project/config/pub/useDepositaires',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return useDepositaires
			},
			async () => {
				const { data: useFIP } = await apiCall(
					'/data/:project/config/pub/useFIP',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return useFIP
			},
			async () => {
				const { data: usePoI } = await apiCall(
					'/data/:project/config/pub/usePoI',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return usePoI
			},
			async () => {
				const { data: useQRFhp } = await apiCall(
					'/data/:project/config/pub/useQRFhp',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return useQRFhp
			},
			async () => {
				const { data: useQRFip } = await apiCall(
					'/data/:project/config/pub/useQRFip',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return useQRFip
			},
			async () => {
				const { data: kioskConfig } = await apiCall(
					'/data/:project/config/pub/kioskConfig',
					{
						type: true,
						params: { project: 'default' },
					},
				)
				return kioskConfig
			},
		] as const)

		const flags = new Set<ProjectFlag>()
		if (enableFhp) flags.add('fhp')
		if (enableFhl) flags.add('fhl')
		if (useDepositaires) flags.add('depositaires')
		if (useFIP === 'specific') flags.add('fip')
		if (usePoI) flags.add('poi')
		if (useQRFhp.use !== 'off' || useQRFip.use !== 'off') flags.add('qr')
		if (filterVisibleBranches(branches).length > 1) flags.add('branches')
		if (kioskConfig) flags.add('kiosk')
		dispatch(setProjectFlags([...flags]))
	} catch (e) {
		console.error('updateProject', e)
		dispatch(clearProject())
	}
}
